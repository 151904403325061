<div class="row">
  <div class="col-lg-8">
    <h3 class="mb-0">Give feeback</h3>
  </div>

  <div class="col-lg-4" *ngIf="feedbackResult">
    <p class="mb-1">
      Feedback result:
      <span role="button" [ngbTooltip]="'Each mentor is required to provide minimum 3 feedbacks daily. This progress bar will help track your progress.'">
        <i class="bi bi-info-circle-fill"></i>
      </span>
    </p>

    <div class="progress">
      <div
        [ngClass]="{
        'bg-warning': (feedbackResult.score?.percentage || 0) > 25 && (feedbackResult.score?.percentage || 0) < 75,
        'bg-success': (feedbackResult.score?.percentage || 0) >= 75
        }"
        [ngStyle]="{'width': feedbackResult.score?.percentage + '%'}"
        class="progress-bar"
        role="progressbar">
        <span class="progress-bar-label">
          {{ feedbackResult.score?.ui }}
        </span>
      </div>
    </div>
  </div>
</div>
