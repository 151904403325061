<div class="page feedback-page">
  <div class="container">
    <div class="row">
      <!-- Logo -->
      <div class="col-2">
        <a [routerLink]="['..', 'home']">
          <div class="logo w-100 mt-3"></div>
        </a>
      </div>
      <div class="col-10">
        <ul class="d-flex justify-content-end mt-3">
          <li class="me-2">
            <a [routerLink]="['..', 'home']" class="btn btn-sm btn-outline-warning">Tasks</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['..', 'feedback']" class="btn btn-sm btn-success">Give feedback</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-feedback-header [feedbackResult]="feedbackResult"></app-feedback-header>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-12">
        <form [formGroup]="form">
          <div class="mb-3">
            <label class="form-label" for="your-name">Name <span class="text-danger">*</span></label>
            <select class="form-select" formControlName="mentorName" id="your-name" (change)="fetchMentorDetails()">
              <option hidden selected>Select mentor...</option>
              <option *ngFor="let mentor of mentors" [value]="mentor">
                {{ mentor }}
              </option>
            </select>

            <p class="form-text fst-italic">
              if your name is not listed here, please reach out to Visam on discord
            </p>
          </div>

          <div class="mb-3">
            <label class="form-label" for="select-task">Which task you choose? <span
              class="text-danger">*</span></label>
            <select class="form-select" formControlName="taskTitle" id="select-task">
              <option hidden selected>Select task...</option>
              <option *ngFor="let task of tasks" [value]="task.title">
                {{ task.title }}
              </option>
            </select>
          </div>

          <div class="mb-3">
            <label class="form-label" for="your-question">What is your question? <span
              class="text-danger">*</span></label>
            <textarea class="form-control" formControlName="question" id="your-question"
                      placeholder="Please use the same question you fed to the app" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label class="form-label" for="your-evaluation-answer">What is your evaluation for the answer? <span
              class="text-danger">*</span></label>
            <textarea class="form-control" formControlName="evaluation" id="your-evaluation-answer" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label class="form-label" for="your-improvements">How do you see the answer could be better? <span
              class="text-primary">(Optional)</span></label>
            <textarea class="form-control" formControlName="improvements" id="your-improvements" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label class="form-label" for="your-shortcoming-answer">What is the shortcoming of the answer? <span
              class="text-primary">(Optional)</span></label>
            <textarea class="form-control" formControlName="problems" id="your-shortcoming-answer" rows="3"></textarea>
          </div>

          <div class="mb-3">
            <label class="form-label" for="seen-before">If there was a shortcoming, did you see it before? <span
              class="text-primary">(Optional)</span></label>
            <textarea class="form-control" formControlName="seenBefore" id="seen-before"
                      placeholder="Please provide the task title and your question, so that we can repeat the behavior"
                      rows="3"></textarea>
          </div>

          <hr class="mt-5">

          <div class="row">
            <div class="col-lg-6">
              <button (click)="showFeedbackDetails()" class="btn btn-primary btn-sm" type="button">Submit the form
              </button>
            </div>

            <div class="col-lg-6 text-end">
              <a class="btn btn-light btn-sm" routerLink="/">Go back</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
