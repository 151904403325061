import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Feedback } from '../../../models';

@Component({
  selector: 'app-feedback-confirm',
  templateUrl: './feedback-confirm.component.html',
  styleUrls: ['./feedback-confirm.component.scss']
})
export class FeedbackConfirmComponent {

  @Input() feedback!: Feedback;

  constructor(public activeModal: NgbActiveModal) {
  }
}
