import { environment } from '../../environments/environment';

export const urls = {
  conversation: '/conversation',
  taksDays: '/tasks/days',
  tasks: '/tasks',
  task: '/tasks/:taskId',
  taskByDay: '/tasks/ByDay/:day',
  feedback: '/feedback',
  mentorFeedback: '/feedback/:mentorName',
  mentors: '/mentors'
};

export const urlBuilder = (url: string, params: { [key: string]: string | number } = {}): string => {
  for (const key in params) {
    const value = params[key];
    url = url.replace(`:${key}`, value.toString());
  }

  return environment.apiUrl + url;
}
