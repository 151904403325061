<div class="page home w-100 pt-0 p-3">
  <div class="w-75 m-auto">
    <div class="container">
      <div class="row">
        <!-- Logo -->
        <div class="col-2">
          <a [routerLink]="['..', 'home']">
            <div class="logo w-100 mt-3"></div>
          </a>
        </div>
        <div class="col-10">
          <ul class="d-flex justify-content-end mt-3">
            <li class="me-2">
              <a [routerLink]="['..', 'home']" class="btn btn-sm btn-warning">Tasks</a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['..', 'feedback']" class="btn btn-sm btn-outline-success">Give feedback</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <!-- TasksDays -->
        <div class="col-6">
          <div class="tasks mb-4">
            <select (change)="selectDay()" [(ngModel)]="selectedDay" class="form-select">
              <option [ngValue]="null" hidden selected>Select day...</option>
              <option *ngFor="let day of days" [ngValue]="day.day">
                {{ day.day }}
              </option>
            </select>
          </div>
        </div>

        <!-- Tasks -->
        <div class="col-6">
          <div class="tasks mb-4">
            <select (change)="changeTask()" [(ngModel)]="selectedTask" [disabled]="!selectedDay" class="form-select">
              <option [ngValue]="null" hidden selected>Select task...</option>
              <option *ngFor="let task of tasks" [ngValue]="task">
                {{ task.title }}
              </option>
            </select>
          </div>


        </div>
      </div>
      <div class="row">
        <div *ngIf="selectedTask">
          <div class="d-flex justify-content-between align-items-center">
            <p class="form-text mb-0">Selected task: <span class="fw-bold">{{ selectedTask.title }}</span></p>

            <div class="d-flex align-items-center">
              <a [routerLink]="['..', 'feedback']" class="btn btn-secondary btn-sm me-2">Give feedback</a>
              <button (click)="fetchTask()" [disabled]="requestTask"
                class="btn btn-primary btn-sm d-flex align-items-center">
                Show task
                <ng-template [ngIf]="requestTask">
                  <span class="spinner-border text-white spinner-border-sm ms-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </span>
                </ng-template>
              </button>
            </div>
          </div>

          <hr>

          <div class="home__chat">
            <ul class="list-group">
              <ng-container *ngFor="let qA of questionAndAnswers; let i = index">
                <li [ngClass]="{'border-1': i !== 0}" class="list-group-item d-flex align-items-start">
                  <!-- Question -->
                  <i class="bi bi-chat-right-text me-2 text-primary"></i>
                  <div class="fw-bold home__question overflow-x-auto">
                    {{ qA.question.content }}
                  </div>
                </li>

                <li class="list-group-item d-flex align-items-baseline bg-light mb-3 overflow-x-auto">
                  <ng-template [ngIf]="qA.answer">
                    <i class="bi bi-robot me-2 text-primary"></i>

                    <div>
                      <ng-container *ngFor="let a of qA.answers">
                        <p *ngIf="a.text">
                          {{ a.text }}
                        </p>

                        <pre *ngIf="a.codeSnippet"><code
                          [class]="'language-' + (a.language ? a.language : 'javascript')"
                          appPrismHighlight>{{ a.codeSnippet }}</code></pre>
                      </ng-container>
                    </div>
                  </ng-template>

                  <ng-template [ngIf]="!qA.answer">
                    <i class="bi bi-robot me-2 text-primary"></i> <i class="carot"></i>
                  </ng-template>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


  <footer class="home__footer w-100 position-fixed bottom-0 start-0 end-0 bg-light p-3">
    <div class="w-75 m-auto">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <textarea #textAreaEl (keyup.enter)="askQuestion(); textAreaEl.style.height = 'auto'"
                (ngModelChange)="autoResizeTextArea($event, textAreaEl)" [disabled]="!selectedTask"
                [ngModel]="sendMessage" class="form-control" placeholder="Send a message" rows="1">
              </textarea>
              <button (click)="askQuestion(); textAreaEl.style.height = 'auto'" [disabled]="!selectedTask"
                class="btn btn-primary input-group-text">
                <i class="bi bi-send"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>