import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { urlBuilder, urls } from '../../constants';
import { Answer, Conversation, Day, Task } from '../../models';
import { v4 as uuidv4 } from 'uuid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeTaskComponent } from './home-task/home-task.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  tasks: Task[] = [];
  selectedTask: Task | null = null;
  days: Day[] = [];
  selectedDay: string | null = null;
  sendMessage: string = '';
  questionAndAnswers: any[] = [];
  uuid: string = '';
  subscription: Subscription = new Subscription();
  requestTask: boolean = false;

  constructor(private http: HttpClient,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.uuid = uuidv4();
    this.fetchTasksDays();
    // this.fetchTasks();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  fetchTasksDays(): void {
    const endpoint = urlBuilder(urls.taksDays);

    this.subscription.add(
      this.http.get<{ days: Day[] }>(endpoint).subscribe(({days}) => {
        this.days = days;
      })
    );
  }

  fetchTasks(): void {
    const endpoint = urlBuilder(urls.tasks);

    this.subscription.add(
      this.http.get(endpoint).subscribe((response: any) => {
        this.tasks = response.result;
      })
    );
  }

  fetchTask(): void {
    this.requestTask = true;

    const endpoint = urlBuilder(urls.task, {
      taskId: this.selectedTask?.id as string
    });

    this.subscription.add(
      this.http.post(endpoint, {}).subscribe(task => {
        this.showTaskModal(task);

        this.requestTask = false;
      }, () => this.requestTask = false)
    );
  }

  showTaskModal(task: any): void {
    if (!task) {
      return;
    }

    const modalRef = this.modalService.open(HomeTaskComponent, {size: 'xl'});
    modalRef.componentInstance.task = task;
  }

  selectDay(): void {
    const {selectedDay} = this;

    if (!selectedDay) {
      return;
    }

    const url = urlBuilder(urls.taskByDay, {
      day: selectedDay
    });

    this.subscription.add(
      this.http.post(url, {}).subscribe((response: any) => {
        this.tasks = response.result;
      })
    );
  }

  changeTask() {
    this.questionAndAnswers = [];
    this.sendMessage = '';
    this.uuid = uuidv4();
  }

  askQuestion(): void {
    const {sendMessage, selectedTask, questionAndAnswers} = this;

    const conversation: Conversation = {
      question: {
        role: 'user',
        content: sendMessage
      },
      conversationId: this.uuid,
      taskId: selectedTask?.id
    };

    const url = urlBuilder(urls.conversation);
    questionAndAnswers.push(conversation);

    this.sendMessage = '';

    this.subscription.add(
      this.http.post<{data:Answer}>(url, conversation).subscribe(response => {
        const lastQuestion = questionAndAnswers.length - 1;

        const answer = response.data.answer as string;

        questionAndAnswers[lastQuestion].answers = [];

        if (answer && answer.trim().length) {
          questionAndAnswers[questionAndAnswers.length - 1].answers = this.modifyAnswer(answer);
        }

        questionAndAnswers[lastQuestion].answer = answer;
      })
    );
  }

  modifyAnswer(inputString: string): any[] {
    // Use a regular expression to extract the text and code snippets with their language identifiers
    const regex = /```(\w+)\n([\s\S]*?)```/gs;
    const matches = [...inputString.matchAll(regex)];

    // Separate the pure text from the code snippets
    const sections: any[] = [];
    let lastIndex = 0;

    for (const match of matches) {
      const language = match[1];
      const codeSnippet = match[2].trim();
      const text = inputString.substring(lastIndex, match.index);
      sections.push({language, codeSnippet, text});

      if (match.index !== undefined) {
        lastIndex = match.index + match[0].length;
      }
    }

    // If there is remaining text after the last code snippet, add it to the sections
    if (lastIndex < inputString.length) {
      const text = inputString.substring(lastIndex);
      sections.push({language: '', codeSnippet: '', text});
    }

    return sections;
  }

  autoResizeTextArea($event: any, textarea: HTMLTextAreaElement): void {
    this.sendMessage = $event;

    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }
}
