<ng-template [ngIf]="task">
  <div class="modal-header">
    <h4 class="modal-title">Task type: <b>{{ task.type }}</b></h4>
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
  </div>
  <div class="modal-body">
    <p *ngIf="task.content">
      {{ task.content }}
    </p>

    <pre *ngIf="task.code"><code
      class="language-javascript"
      appPrismHighlight>{{ task.code }}</code></pre>
  </div>
  <div class="modal-footer">
    <button (click)="activeModal.close('Close click')" class="btn btn-outline-primary" type="button">Close</button>
  </div>
</ng-template>
