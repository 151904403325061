import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import * as Prism from 'prismjs';

@Directive({
  selector: '[appPrismHighlight]'
})
export class PrismHighlightDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) {
  }

  ngAfterViewInit(): void {
    Prism.highlightElement(this.elementRef.nativeElement);
  }
}
