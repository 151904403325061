import { Component, Input } from '@angular/core';
import { FeedbackResult } from '../../../models';

@Component({
  selector: 'app-feedback-header',
  templateUrl: './feedback-header.component.html',
  styleUrls: ['./feedback-header.component.scss']
})
export class FeedbackHeaderComponent {
  @Input() feedbackResult?: FeedbackResult;
}
