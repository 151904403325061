import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ErrorInterceptor } from './interceptors/error.interceptor';

import { PrismHighlightDirective } from './directives';
import { AppComponent } from './app.component';
import { HomeComponent, NotFoundComponent } from './pages';
import { ProgressbarCircleComponent, ToastsContainerComponent } from './components';
import { HomeTaskComponent } from './pages/home/home-task/home-task.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { FeedbackHeaderComponent } from './pages/feedback/feedback-header/feedback-header.component';
import { FeedbackConfirmComponent } from './pages/feedback/feedback-confirm/feedback-confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeTaskComponent,
    NotFoundComponent,
    PrismHighlightDirective,
    FeedbackComponent,
    FeedbackHeaderComponent,
    FeedbackConfirmComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    ToastsContainerComponent,
    ProgressbarCircleComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
