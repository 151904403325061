import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { urlBuilder, urls } from '../../constants';
import { Feedback, FeedbackResult, Task } from '../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackConfirmComponent } from './feedback-confirm/feedback-confirm.component';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {

  form!: FormGroup;
  mentors: string[] = [];
  tasks: Task[] = [];
  feedbackResult?: FeedbackResult;

  subscription: Subscription = new Subscription();

  constructor(private http: HttpClient,
              private modalService: NgbModal,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.fetchMentors();
    this.fetchTasks();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm(): void {
    this.form = new FormGroup({
      mentorName: new FormControl(null, [Validators.required]),
      taskTitle: new FormControl(null, [Validators.required]),
      question: new FormControl(null, [Validators.required]),
      evaluation: new FormControl(null, [Validators.required]),
      improvements: new FormControl(null),
      problems: new FormControl(null),
      seenBefore: new FormControl(null),
    });
  }

  fetchMentors(): void {
    const url = urlBuilder(urls.mentors);

    this.subscription.add(
      this.http.get<{ mentors: string[] }>(url).subscribe(({ mentors}) => this.mentors = mentors)
    );
  }

  fetchTasks(): void {
    const endpoint = urlBuilder(urls.tasks);

    this.subscription.add(
      this.http.get<{ result: Task[] }>(endpoint).subscribe(({result}) => this.tasks = result)
    );
  }

  showFeedbackDetails(): void {
    const modalRef = this.modalService.open(FeedbackConfirmComponent, {size: 'lg'});
    modalRef.componentInstance.feedback = this.form.value;

    modalRef.result.then(result => {
      if (result && typeof result !== 'string') {
        this.submit();
      }
    });
  }

  fetchMentorDetails() {
    const mentorName = this.form.get('mentorName')?.value;

    if (!mentorName || !mentorName.trim().length) {
      return;
    }

    const url = urlBuilder(urls.mentorFeedback, {mentorName});

    this.subscription.add(
      this.http.post<FeedbackResult>(url, {})
        .subscribe(feedbackResult => {
          const feedbackResultTemp = feedbackResult;

          if (feedbackResultTemp.score) {
            const score = feedbackResultTemp.score;
            feedbackResultTemp.score.ui = score?.feedbackCount + '/' + score?.targetCount;
            feedbackResultTemp.score.percentage = ((score.feedbackCount as number) / (score.targetCount as number)) * 100;
          }

          this.feedbackResult = feedbackResultTemp;
        })
    );
  }

  submit(): void {
    const {form} = this;

    if (form.invalid) {
      return;
    }

    const url = urlBuilder(urls.feedback);
    const body: Feedback = this.form.value;

    this.subscription.add(
      this.http.post(url, body).subscribe(() => {
        this.toastService.show('Feedback submitted successfully', {classname: 'bg-success text-light'});
        this.feedbackResult = undefined;
        this.form.reset();
      })
    );
  }
}
