<ngb-toast
  (hidden)="toastService.remove(toast)"
  *ngFor="let toast of toastService.toasts"
  [autohide]="true"
  [class]="toast.classname"
  [delay]="toast.delay || 5000"
>
  <ng-template [ngIfElse]="text" [ngIf]="isTemplate(toast)">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
