<div class="modal-header">
  <h4 class="modal-title">Feedback details</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
</div>

<div class="modal-body">
  <ul *ngIf="feedback" class="list-group list-group-flush">
    <li class="list-group-item d-flex align-items-start">
      <div class="ms-2 me-auto">
        <div><span class="fw-bold">Name:</span> {{ feedback.mentorName }}</div>
      </div>
    </li>
    <li class="list-group-item d-flex align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">Which task you choose?:</div>
        <span >{{ feedback.taskTitle }}</span>
      </div>
    </li>

    <li class="list-group-item d-flex align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">What is your question?</div>
        <span>{{ feedback.question }}</span>
      </div>
    </li>

    <li class="list-group-item d-flex align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">What is your evaluation for the answer?</div>
        <span>{{ feedback.evaluation }}</span>
      </div>
    </li>

    <li class="list-group-item d-flex align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">How do you see the answer could be better?</div>
        <span>{{ feedback.improvements || 'n/a' }}</span>
      </div>
    </li>

    <li class="list-group-item d-flex align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">What is the shortcoming of the answer?</div>
        <span>{{ feedback.problems || 'n/a' }}</span>
      </div>
    </li>

    <li class="list-group-item d-flex align-items-start">
      <div class="ms-2 me-auto">
        <div class="fw-bold">If there was a shortcoming, did you see it before?</div>
        <span>{{ feedback.seenBefore || 'n/a' }}</span>
      </div>
    </li>
  </ul>
</div>

<div class="modal-footer">
  <button (click)="activeModal.close('Cancel click')" class="btn btn-outline-secondary" type="button">Cancel</button>
  <button (click)="activeModal.close(true)" class="btn btn-primary">Confirm</button>
</div>
